/* eslint-disable */

/*
 * centre content vertically
 */

html,
body {
  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
